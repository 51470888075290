'use client';

import { useEffect, useMemo, useState } from 'react';
import {
  useAutocompleteQuery,
  useGetWidgetQuery
} from '@akinon/next/data/client/misc';

import { LoaderSpinner, Link, Icon } from '@theme/components';
import { useDebounce, useLocalization } from '@akinon/next/hooks';
import { IProduct } from '@theme/types';
import { ProductItem } from '@theme/views/product-item';
import { PopularCategories } from './popularCategories';
import { PopularProducts } from './popularProducts';
import { pushViewSearchResult } from '@theme/utils/gtm';

interface ResultsProps {
  searchText: string;
}

const MINIMUM_SEARCH_LENGTH = 3;

export default function Results(props: ResultsProps) {
  const { searchText } = props;
  const { t } = useLocalization();
  const debouncedSearchText = useDebounce(searchText, 400);
  const [isLoadingFormatJSON, setIsLoadingFormatJSON] = useState(false);
  const [productsByUrl, setProductsByUrl] = useState<IProduct[]>([]);

  const { data: searchData } = useGetWidgetQuery('search');
  const { data: popularProductsData } = useGetWidgetQuery('popular-products');

  const {
    currentData,
    isFetching,
    isLoading: isLoadingAutoComplete
  } = useAutocompleteQuery(debouncedSearchText, {
    refetchOnMountOrArgChange: true,
    skip: debouncedSearchText.length < MINIMUM_SEARCH_LENGTH
  });

  const categories = useMemo(
    () =>
      currentData?.groups.find((group) => group.suggestion_type === 'Category')
        ?.entries ?? [],
    [currentData]
  );

  const products = useMemo(
    () =>
      currentData?.groups.find((group) => group.suggestion_type === 'Product')
        ?.entries ?? [],
    [currentData]
  );

  const fetchProductsByUrls = async (urls: string[]) => {
    setIsLoadingFormatJSON(true);
    try {
      const fetchPromises = urls.map((url) =>
        fetch(url).then((response) => response.json())
      );
      const results = await Promise.all(fetchPromises);

      const allProducts = results.map((result: any) => result.product);

      setProductsByUrl(allProducts);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoadingFormatJSON(false);
    }
  };

  useEffect(() => {
    if (products.length > 0) {
      const productUrls = products.map(
        (product) => product.url + '/?format=json'
      );
      fetchProductsByUrls(productUrls);
    }
  }, [products]);

  const renderCategories = () => {
    return categories.map((category, index) => {
      // Find the match index
      const matchIndex = category.label
        .toLowerCase()
        .indexOf(searchText.toLowerCase());

      if (matchIndex === -1) {
        // No match found, render normally
        return (
          <li key={index} className="flex gap-x-4 items-center">
            <Icon name="search" size={16} className="text-cerebral-grey" />
            <Link href={category.url} className="text-squant">
              {category.label}
            </Link>
          </li>
        );
      }

      // Split the label into three parts: before, match, and after
      const beforeMatch = category.label.slice(0, matchIndex);
      const match = category.label.slice(
        matchIndex,
        matchIndex + searchText.length
      );
      const afterMatch = category.label.slice(matchIndex + searchText.length);

      return (
        <li key={index} className="flex gap-x-4 items-center">
          <Icon name="search" size={16} className="text-cerebral-grey" />
          <Link href={category.url} className="text-squant">
            {beforeMatch}
            <span className="text-black">{match}</span>
            {afterMatch}
          </Link>
        </li>
      );
    });
  };

  if (isLoadingAutoComplete || isFetching || isLoadingFormatJSON) {
    return <LoaderSpinner />;
  }

  if (
    categories.length === 0 &&
    products.length === 0 &&
    debouncedSearchText.length >= MINIMUM_SEARCH_LENGTH
  ) {
    return <p className="text-center">{t('common.search.not_found')}</p>;
  }

  return (
    <div className="w-full flex flex-col gap-6 max-w-[60.25rem] mx-auto">
      {searchData?.attributes?.popular_categories &&
        debouncedSearchText.length < MINIMUM_SEARCH_LENGTH && (
          <>
            <PopularCategories
              data={searchData?.attributes?.popular_categories}
            />
            <PopularProducts
              data={popularProductsData?.products}
              title={popularProductsData?.attributes?.title?.value}
            />
          </>
        )}

      {categories.length > 0 && (
        <ul className="flex flex-col gap-3">{renderCategories()}</ul>
      )}

      {debouncedSearchText.length >= MINIMUM_SEARCH_LENGTH && (
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-3 lg:gap-x-4 lg:gap-y-6">
          {productsByUrl.map((product, index) => (
            <ProductItem
              key={index}
              index={index}
              width={384}
              height={384}
              product={product}
              className='!border-none !p-0'
              onClick={() => pushViewSearchResult(debouncedSearchText)}
            />
          ))}
        </div>
      )}
    </div>
  );
}
