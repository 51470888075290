'use client';

import { ReactNode, useMemo, useRef, useCallback, useState } from 'react';
import { useGetBasketQuery } from '@akinon/next/data/client/basket';
import { useAppDispatch, useAppSelector } from '@akinon/next/redux/hooks';
import { useSession } from 'next-auth/react';
import { useRouter, useLocalization } from '@akinon/next/hooks';

import {
  closeMiniBasket,
  toggleMiniBasket
} from '@akinon/next/redux/reducers/root';
import clsx from 'clsx';
import { ROUTES } from '@theme/routes';

import MiniBasket from './mini-basket';
import { Badge, Icon, Link, LanguageSelect } from '@theme/components';
import { useOnClickOutside } from '@akinon/next/hooks';
import { usePathname } from 'next/navigation';
import UserIcon from '@theme/components/icons/userIcon';
import HeartStraightIcon from '@theme/components/icons/heartStraightIcon';
import CartIcon from '@theme/components/icons/cartIcon';
import { RootState } from '@theme/redux/store';
import useWindowResize from '@theme/hooks/use-window-resize';

interface MenuItem {
  label: string;
  url?: string;
  action?: () => void;
  icon: string;
  className?: string;
  badge?: ReactNode;
  miniBasket?: ReactNode;
  dataTestId?: string;
  subMenu?: MenuItem[];
  hidden?: boolean;
}

interface ActionMenuProps {
  isScrolled: boolean;
}

export default function ActionMenu(props: ActionMenuProps) {
  const { isScrolled } = props;
  const dispatch = useAppDispatch();
  const { data: session, status } = useSession();
  const pathname = usePathname();
  const { steps } = useAppSelector((state: RootState) => state.checkout);

  const { data } = useGetBasketQuery();
  const { t } = useLocalization();
  const router = useRouter();

  const totalQuantity = useMemo(() => data?.total_quantity ?? 0, [data]);

  const { open: miniBasketOpen } = useAppSelector(
    (state) => state.root.miniBasket
  );
  const miniBasketRef = useRef(null);
  const windowSize = useWindowResize();

  const accountUrl = useMemo(() => {
    return status === 'authenticated' ? ROUTES.ACCOUNT : ROUTES.LOGIN;
  }, [status]);

  const [hoverAccount, setHoverAccount] = useState(false);
  const email = session?.user?.email?.toLowerCase();
  const languageSelectAllowedEmails = ['ecommerc.io', 'zsazsazsu.com.tr'];

  // Check if any of the allowed emails are included in the session email (partial match allowed like ecommerc.io by some())
  const showLanguageSelect = languageSelectAllowedEmails.some((allowedEmail) =>
    email?.includes(allowedEmail.toLowerCase())
  );
  const MenuItems: MenuItem[] = [
    {
      label: 'Favourite Products',
      url: ROUTES.ACCOUNT_WISHLIST,
      icon: 'heart-straight',
      dataTestId: 'header-favourite',
      hidden: pathname === '/orders/checkout'
    },
    {
      label: 'Account',
      icon: 'user',
      url: accountUrl,
      subMenu:
        status === 'authenticated'
          ? [
              { label: t('common.header.nice_to_see_you'), icon: '' },

              {
                label: session?.user?.firstName,
                url: ROUTES.ACCOUNT,
                icon: ''
              },

              {
                label: t('account.base.menu.my_orders'),
                url: ROUTES.ACCOUNT_ORDERS,
                icon: ''
              },
              {
                label: t('account.base.menu.my_addresses'),
                url: ROUTES.ACCOUNT_ADDRESS,
                icon: ''
              },
              {
                label: t('account.change_password.header.title'),
                url: ROUTES.ACCOUNT_CHANGE_PASSWORD,
                icon: ''
              },
              {
                label: t('account.my_orders.return.easy_return'),
                url: ROUTES.ACCOUNT_ORDERS,
                icon: ''
              },
              {
                label: t('account.base.menu.help'),
                url: ROUTES.FAQ,
                icon: ''
              }
            ]
          : [
              {
                label: t('auth.login.form.submit'),
                url: ROUTES.LOGIN,
                icon: ''
              },
              {
                label: t('common.header.sign_up_now'),
                url: ROUTES.REGISTER,
                icon: ''
              },
              {
                label: t('account.my_orders.detail.order_tracking'),
                url: ROUTES.ACCOUNT_ORDERS,
                icon: ''
              },
              {
                label: t('account.my_orders.return.easy_return'),
                url: ROUTES.ACCOUNT_ORDERS,
                icon: ''
              },
              {
                label: t('account.base.menu.help'),
                url: ROUTES.FAQ,
                icon: ''
              }
            ]
    },
    {
      label: 'Basket',
      action() {
        if (windowSize.width < 1024) {
          router.push(ROUTES.BASKET);
        } else {
          dispatch(toggleMiniBasket());
        }
      },
      icon: 'cart',
      dataTestId: 'header-basket',
      hidden: pathname === '/orders/checkout',
      badge: (
        <Badge
          className={clsx(
            'w-4',
            totalQuantity === 0
              ? 'bg-primary text-gray-500'
              : 'bg-secondary-500 text-white'
          )}
        >
          {totalQuantity}
        </Badge>
      ),
      miniBasket: <MiniBasket />
    }
  ];

  return (
    <ul className="flex items-center space-x-4 lg:space-x-8">
      {showLanguageSelect ? (
        <div className="font-kronaOne text-center uppercase max-lg:hidden">
          <LanguageSelect />
        </div>
      ) : null}
      {MenuItems.map((menu) => (
        <li
          key={`${menu.label}-${menu.url}`}
          className={clsx(
            'navbar-profile-icon flex items-center relative text-white',
            menu.className
          )}
          ref={menu.miniBasket ? miniBasketRef : null}
          onMouseEnter={() => menu.label === 'Account' && setHoverAccount(true)}
          onMouseLeave={() =>
            menu.label === 'Account' && setHoverAccount(false)
          }
        >
          {menu.action ? (
            <button
              onClick={menu.action}
              data-testid={menu.dataTestId}
              className={clsx({ hidden: menu.hidden })}
            >
              {menu.icon === 'user' ? (
                <UserIcon
                  className={clsx(
                    !isScrolled
                      ? 'fill-white [&_path]:fill-white'
                      : 'fill-plum-cheese [&_path]:plum-cheese'
                  )}
                />
              ) : menu.icon === 'cart' ? (
                <CartIcon
                  className={clsx(
                    !isScrolled
                      ? 'fill-white [&_path]:fill-white'
                      : 'fill-plum-cheese [&_path]:plum-cheese'
                  )}
                />
              ) : (
                <HeartStraightIcon
                  className={clsx(
                    !isScrolled
                      ? 'fill-white [&_path]:fill-white'
                      : 'fill-plum-cheese [&_path]:plum-cheese'
                  )}
                />
              )}
              {menu.badge}
            </button>
          ) : (
            <Link
              href={menu.url ?? '#'}
              passHref={true}
              onClick={(event) => {
                if (menu.action) {
                  event.preventDefault();
                  menu.action();
                }
              }}
              data-testid={menu.dataTestId}
            >
              {menu.icon === 'user' ? (
                <UserIcon
                  className={clsx(
                    !isScrolled
                      ? 'fill-white [&_path]:fill-white'
                      : 'fill-plum-cheese [&_path]:plum-cheese'
                  )}
                />
              ) : menu.icon === 'cart' ? (
                <CartIcon
                  className={clsx(
                    !isScrolled
                      ? 'fill-white [&_path]:fill-white'
                      : 'fill-plum-cheese [&_path]:plum-cheese'
                  )}
                />
              ) : (
                <HeartStraightIcon
                  className={clsx(
                    !isScrolled
                      ? 'fill-white [&_path]:fill-white'
                      : 'fill-plum-cheese [&_path]:plum-cheese',
                    {
                      hidden: pathname === '/orders/checkout'
                    }
                  )}
                />
              )}

              {menu.badge}
            </Link>
          )}
          {menu.miniBasket}
          {hoverAccount && menu.subMenu && (
            <div
              className={`profile-hover__container ${
                status === 'authenticated'
                  ? 'translate-y-[12rem] before:-top-2'
                  : 'translate-y-[10.313rem] before:-top-3'
              }  z-20 absolute bg-white text-black -translate-x-[10.5rem] border w-[15.625rem] h-fit border-plum-cheese md:block hidden before:content-['..']  before:h-4 before:absolute before:bg-transparent before:text-transparent before:w-[15rem]`}
            >
              <Icon
                name="profile-polygon"
                size={12}
                className="z-30 fill-plum-cheese bg-primary-300"
              />

              <ul className="px-5 pt-6">
                {menu.subMenu.map((subItem) => (
                  <li
                    key={subItem.label}
                    className={clsx(
                      subItem.label === 'Giriş Yap'
                        ? 'login text-white bg-black cursor-pointer rounded-[2rem] py-[0.625rem] px-4 w-[13.125rem] hover:bg-red-100 text-center font-kronaOne text-sm mb-6 uppercase'
                        : subItem.label === 'Hemen Üye Ol'
                        ? 'sign-up font-kronaOne text-sm text-center uppercase pb-[1.813rem] border-b border-plum-cheese mb-4'
                        : subItem.label === t('common.header.nice_to_see_you')
                        ? 'mb-3 text-sm cursor-default pointer-events-none'
                        : subItem.label === session?.user?.firstName
                        ? 'text-2xl border-b border-plum-cheese pb-4'
                        : subItem.label === t('account.base.menu.my_orders')
                        ? 'mt-4 text-sm mb-6'
                        : subItem.label ===
                          t('account.change_password.header.title')
                        ? 'border-b border-plum-cheese pb-4 text-sm'
                        : t('account.my_orders.return.easy_return')
                        ? 'mt-4 text-sm mb-6'
                        : subItem.label === t('common.header.nice_to_see_you')
                        ? ''
                        : 'hover:bg-gray-200 mb-6 text-sm leading-none'
                    )}
                  >
                    <Link href={subItem.url} className="hover:underline">
                      {subItem.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
      ))}
    </ul>
  );
}
