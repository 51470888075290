'use client';

import { Accordion, Link } from '@theme/components';
import FooterMenuListItem from './footer-menu-list-item';
import { useState } from 'react';
import { WidgetResultType } from '@akinon/next/types';

type FooterMenuItem = {
  value: {
    url: string;
    id: string;
    title: string;
    hasSubtitle?: boolean;
    isOpen?: boolean;
  };
  kwargs: { data_type: 'nested'; value: null };
};

type FooterMenuType = {
  footer_menu_titles: FooterMenuItem[];
  footer_menu_subtitles: FooterMenuItem[];
};

export default function FooterMenu({
  data
}: {
  data: WidgetResultType<FooterMenuType>;
}) {
  const titles = data?.attributes?.footer_menu_titles || [];
  const subtitles = data?.attributes?.footer_menu_subtitles;
  const [expandedAccordionId, setExpandedAccordionId] = useState('');

  Object.keys(titles).map((item) => {
    const subtitlesOfTitles = Object.keys(subtitles).filter(
      (subtitleItem) =>
        subtitles[subtitleItem]?.value.id === titles[item].value.id
    );
    if (subtitlesOfTitles.length === 0) {
      titles[item].value.hasSubtitle = false;
    } else {
      titles[item].value.hasSubtitle = true;
    }
  });

  const handleAccordionClick = (id: string) => {
    if (id === expandedAccordionId) {
      setExpandedAccordionId('');
    } else {
      setExpandedAccordionId(id);
    }
  };

  return (
    <div className="text-xs flex flex-col lg:justify-between items-start gap-8 lg:flex-row lg:w-[62.5rem] lg:m-auto lg:border-y lg:border-white lg:border-opacity-20 lg:pt-[2rem] lg:pb-[2.625rem] 3xl:w-[90rem] ">
      {titles.map((title) => {
        return (
          <div
            key={`footer-title-${title.value.id}`}
            className="font-medium text-white flex flex-col gap-6 items-start"
            data-testid="footer-categories"
          >
            {title.value.hasSubtitle ? (
              <Accordion
                handleAccordionClick={handleAccordionClick}
                key={title.value.id}
                title={title.value.title}
                accordionId={title.value.id}
                accordionType="footer"
                expandedAccordionId={expandedAccordionId}
                titleClassName="text-sm font-bold lg:mr-[.625rem]"
                iconSize={16}
                icons={['minus', 'plus']}
                className="min-w-[9.875rem] relative !pb-0 border-b justify-center mb-0 [&_i]:bg-white [&_i]:bg-opacity-20 [&_i]:rounded-sm"
                contentWrapperClassName="duration-700 [&_div]:!pb-0"
              >
                <FooterMenuListItem
                  titlesId={title.value.id}
                  subtitles={subtitles}
                />
              </Accordion>
            ) : (
              <h3 className="text-sm font-bold">
                <Link href={title.value.url}>{title.value.title}</Link>
              </h3>
            )}
          </div>
        );
      })}
    </div>
  );
}
