import { createSlice } from '@reduxjs/toolkit';
import { Order } from '@theme/types/order';

export interface IinitialState {
  orderItemList: Order[];
}

const anonymousTrackingSlice = createSlice({
  name: 'orderData',
  initialState: {} as unknown as IinitialState,
  reducers: {
    updateProductList(state, action) {
      state.orderItemList = action.payload;
    }
  }
});

export const { updateProductList } = anonymousTrackingSlice.actions;
export default anonymousTrackingSlice.reducer;
