'use client';

import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FooterSubscriptionFormType } from '@theme/types';
import { useEmailSubscriptionMutation } from '@akinon/next/data/client/misc';
import { useGetWidgetQuery } from '@akinon/next/data/client/misc';
import { Input, Button, Checkbox, Modal } from '@theme/components';
import { useLocalization } from '@akinon/next/hooks';
import { Image } from '@akinon/next/components/image';
import { CustomToastContainer } from '@theme/components/customToastContainer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const subscriptionFormSchema = (t: any) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('common.newsletter_signup.required_email'))
      .required(t('common.newsletter_signup.required')),
    subscribe_contract: yup
      .boolean()
      .oneOf([true], t('common.newsletter_signup.required'))
  });

export default function FooterSubscriptionForm({
  placeholder,
  iconSrc
}: {
  placeholder: string;
  iconSrc: string;
}) {
  const { t } = useLocalization();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [İsMarketingModalOpen, setIsMarketingModalOpen] = useState(false);
  const [emailSubscription] = useEmailSubscriptionMutation();
  const { data: EtkPolicy } = useGetWidgetQuery('etk-policy');
  const { data: Marketing } = useGetWidgetQuery('marketing-disclosure');

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FooterSubscriptionFormType>({
    resolver: yupResolver(subscriptionFormSchema(t))
  });

  const onSubmit: SubmitHandler<FooterSubscriptionFormType> = (data) => {
    emailSubscription(data)
      .unwrap()
      .then(() => {
        toast.success('Mail listesine başarıyla eklendiniz.', {
          position: 'bottom-right',
          autoClose: 1500,
          containerId: 'footerSubscription'
        });
      });
  };

  return (
    <div className="flex flex-col justify-center relative w-full max-w-[800px] lg:max-w-[59.25rem]">
      <div className="[&_div]:!w-full flex justify-center relative  w-full max-w-[50rem] lg:max-w-[59.25rem]">
        <Input
          id="footer-subscription-email"
          type="email"
          placeholder={placeholder}
          className="hover:border-white text-base inline-block outline-none focus:outline-none px-6 py-3 placeholder:text-sm placeholder:text-white w-full border border-white font-sans bg-transparent rounded-[2.625rem] max-w-[59.25rem] lg:px-12 lg:py-5 min-h-10"
          {...register('email')}
          errorClassName="mt-1 mb-0"
          error={errors.email}
          borderColor="white"
          data-testid="newsletter-input"
        />

        <Button
          type="submit"
          appearance="ghost"
          className="px-0 hover:border-transparent hover:bg-transparent outline-none font-semibold h-7 absolute right-6 top-[.4rem] lg:right-12"
          data-testid="newsletter-submit"
          onClick={handleSubmit(onSubmit)}
        >
          <Image src={iconSrc} alt="Arrow" width={28} height={28} />
        </Button>
      </div>

      {/* Marketing Disclosure */}
      <div>
        <p className="mt-4 text-[0.5rem]">
          {t('common.marketing_disclosure.marketing_first')}{' '}
          <button
            type="button"
            className="underline"
            onClick={() => {
              setIsMarketingModalOpen(true);
            }}
          >
            {t('common.marketing_disclosure.marketing_middle')}
          </button>{' '}
          {t('common.marketing_disclosure.marketing_last')}
        </p>
        <Modal
          className="h-4/5 w-4/5 p-4 flex flex-col justify-between items-center md:p-12 [&_h1]:text-center lg:h-1/2 lg:w-1/2"
          portalId="footer-subscription-marketing-modal"
          open={İsMarketingModalOpen}
          setOpen={setIsMarketingModalOpen}
          showCloseButton={false}
        >
          <div
            className="mb-3 overflow-hidden overflow-y-auto"
            dangerouslySetInnerHTML={{
              __html: Marketing?.attributes?.marketing_disclosure?.value
            }}
          />

          <Button
            onClick={() => setIsMarketingModalOpen(false)}
            appearance="outlined"
            className="font-medium px-10 py-2"
            data-testid="newsletter-modal-close-button"
          >
            {t('common.newsletter_signup.close')}
          </Button>
        </Modal>
      </div>

      {/* Etk Policy */}
      <Checkbox
        {...register('subscribe_contract')}
        error={errors.subscribe_contract}
        checkboxType="filled"
        data-testid="newsletter-agreement-checkbox"
        className="text-[0.5rem]"
      >
        {t('common.newsletter_signup.accept_first')}{' '}
        <button
          type="button"
          className="underline"
          onClick={() => {
            setIsModalOpen(true);
          }}
          data-testid="newsletter-agreement"
        >
          {t('common.newsletter_signup.accept_middle')}
        </button>{' '}
        {t('common.newsletter_signup.accept_last')}
        {/* TODO: React toastify ile değiştirilecek */}
        <Modal
          className="h-4/5 w-4/5 p-4 flex flex-col justify-between items-center md:p-12 [&_h1]:text-center lg:h-1/2 lg:w-1/2"
          portalId="footer-subscription-etk-policy-modal"
          open={isModalOpen}
          setOpen={setIsModalOpen}
          showCloseButton={false}
        >
          <div
            className="mb-3 overflow-hidden overflow-y-auto"
            dangerouslySetInnerHTML={{
              __html: EtkPolicy?.attributes?.etk_policy?.value
            }}
          />

          <Button
            onClick={() => setIsModalOpen(false)}
            appearance="outlined"
            className="font-medium px-10 py-2"
            data-testid="newsletter-modal-close-button"
          >
            {t('common.newsletter_signup.close')}
          </Button>
        </Modal>
      </Checkbox>
      <CustomToastContainer containerId="footerSubscription" />
    </div>
  );
}
